<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
      <div class="mt-6 flex items-center justify-between px-6">
          <h4>{{ Object.entries(this.data).length === 0 ? $t("AJOUTER_UN") : $t("MODIFICATION_DE_L") }} {{$t("UTILISATEUR")}}</h4>
          <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>

      <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
        <div class="p-6">

          <!-- <p class="pt-4"> {{$t("Nom")}} <b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required'"
            name="last_name"
            v-model="input.last_name"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('last_name')">{{$t("last_name_error")}}</span>
 -->
         <!--  <p class="pt-4"> {{$t("Prénoms")}} <b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required'"
            name="first_name"
            v-model="input.first_name"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('first_name')">{{$t("first_name_error")}}</span>
 -->
         <!--  <p class="pt-4"> {{$t("username")}} <b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required'"
            name="username"
            v-model="input.username"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('username')">{{$t("username_error")}}</span>
 -->

        <!--   <p class="pt-4"> {{$t("mail")}} <b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required|email'"
            name="email"
            type="email"
            v-model="input.email"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{$t("email_error")}}</span>
 -->
          <p class="pt-4"> {{$t("Phone")}} <b style="color: #ff6141" >*</b> </p>
          <vue-tel-input
              v-model="phoneNumber"
              placeholder="Telephone"
              :mode="national"
              :autoFormat="false"
              :enabledCountryCode="true"
              :enabledFlags="true"
              :validCharactersOnly="true"
              data-vv-validate-on="blur"
              v-validate="'required|min:8|max:8'"
              @validate="yourValidationMethod"
              name="phoneNumber"
              class="w-full"
          >
          </vue-tel-input>
          <span class="text-danger text-sm" v-show="errors.has('phoneNumber')">{{ errors.first("phoneNumber") }}</span>
          
            <p v-if="user_role ==='admin'"> Rôle <b style="color: #ff6141" >*</b> </p>
            <v-select
            v-if="user_role ==='admin'"
              v-validate="'required'"
              name="role"
              :reduce="rep => rep.key"
              class="w-full" label="name"
              v-model="role"
              :options="roles_admin">
            </v-select>
            <span  v-if="user_role ==='admin'" class="text-danger text-sm" v-show="errors.has('role')">{{$t("role_error")}}</span>
            
            <p v-if="user_role ==='adminClient'"> Rôle <b style="color: #ff6141" >*</b> </p>
            <v-select
            v-if="user_role ==='adminClient'"
              v-validate="'required'"
              name="role"
              :reduce="rep => rep.key"
              class="w-full" label="name"
              v-model="role"
              :options="roles_adminclient">
            </v-select>
            <span  v-if="user_role ==='adminClient'" class="text-danger text-sm" v-show="errors.has('role')">{{$t("role_error")}}</span>
           

            <p v-if="user_role ==='managerClient'"> Rôle <b style="color: #ff6141" >*</b> </p>
            <v-select
            v-if="user_role ==='managerClient'"
              v-validate="'required'"
              name="role"
              :reduce="rep => rep.key"
              class="w-full" label="name"
              v-model="role"
              :options="roles_managerClient">
            </v-select>
            <span v-if="user_role ==='managerClient'" class="text-danger text-sm" v-show="errors.has('role')">{{$t("role_error")}}</span>

            <p  v-if="user_role ==='manager'"> Rôle <b style="color: #ff6141" >*</b> </p>
            <v-select
            v-if="user_role ==='manager'"
              v-validate="'required'"
              name="role"
              :reduce="rep => rep.key"
              class="w-full" label="name"
              v-model="role"
              :options="roles_manager">
            </v-select>
            <span  v-if="user_role ==='manager'" class="text-danger text-sm" v-show="errors.has('role')">{{$t("role_error")}}</span>

            <p v-if="role ==='agent'">Région<b style="color: #ff6141">*</b></p>
              <vs-select
              v-if="role ==='agent'"
                  v-model="region"
                  v-validate="'required'"
                  autocomplete
                  clearable
                  class="w-full "
                  name="region"
              >
              <vs-select-item :key="item" :value="item.id" :text="item.name" v-for="item in regions" /></vs-select>
              <span v-if="role ==='agent'" class="text-danger text-sm" v-show="errors.has('region')">{{$t("region_error")}}</span>
        
        </div>

      </component>
      <div class="flex flex-wrap items-center p-6" slot="footer">
        <vs-button class="mr-6" @click="User_validate">{{$t("Soumettre")}}</vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{$t("Annuler")}}</vs-button>
      </div>
  </vs-sidebar>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {French as FrenchLocale} from 'flatpickr/dist/l10n/fr.js'
import { Validator } from 'vee-validate'

const dict = {
  custom: {
    phoneNumber: {
      required: 'Le champ téléphone est obligatoire',
      min: 'Au minimum 8 caractères.',
      max: 'Au minimum 8 caractères.'
    }
  }
}
const input_tempon = {
  role: '',
  phoneNumber:'',
  region:''
}
Validator.localize('en', dict)
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar,
    flatPickr
  },
  data () {
    return {
      user_id:'',
      user_role:'',
      role: '',
      phoneNumber:'',
      region:'',
      showByAdmin: false,
      showByUser: false,
      configdateTimePicker: {
        locale: FrenchLocale
      },
      roles_adminMoozi: [
        {
          key: 'adminMoozi',
          name: 'Administrateur MooziStudio'
        },
        {
          key: 'admin',
          name: 'Administrateur'
        },
        {
          key: 'adminClient',
          name: 'Administrateur client'
        },
        {
          key: 'managerClient',
          name: 'Manager client'
        },        
        {
          key: 'manager',
          name: 'Manager Transvie'
        },        
        {
          key: 'agent',
          name: 'Agent commercial'
        }
      ],
      roles_admin: [
        {
          key: 'admin',
          name: 'Administrateur'
        },
        {
          key: 'adminClient',
          name: 'Administrateur client'
        },
        {
          key: 'managerClient',
          name: 'Manager client'
        },        
        {
          key: 'manager',
          name: 'Manager Transvie'
        },        
        {
          key: 'agent',
          name: 'Agent commercial'
        }
      ],
      roles_adminclient: [
       
        {
          key: 'managerClient',
          name: 'Manager client'
        },               
        {
          key: 'agent',
          name: 'Agent commercial'
        }
      ],
      roles_managerClient: [             
        {
          key: 'agent',
          name: 'Agent commercial'
        }
      ],
      roles_manager: [ 
        {
          key: 'adminClient',
          name: 'Administrateur client'
        },            
        {
          key: 'agent',
          name: 'Agent commercial'
        },
        {
          key: 'managerClient',
          name: 'Manager client'
        }
       
      ],
      regions:[],
      input: JSON.parse(JSON.stringify(input_tempon)),
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        this.initValues()
        this.role = this.data.role
        this.user_id = this.data.id
        this.phoneNumber = this.data.phoneNumber
        this.region = this.data.region.id
        

      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    initValues () {
      this.user_id = ''
      this.role = ''
      this.phoneNumber = ''
      this.region = null
    },
    User_validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitData()
        }
      })
    },
   
    async submitData () {
      this.$vs.loading()
      const input = {
        role : this.role,
        phoneNumber : this.phoneNumber,
        region : this.region
      }
      let url = 'users/'
      let methods = 'post'
      const message = {
        error: this.$t('save_error'),
        success: this.$t('save_sucess_user')
      }
      if (this.user_id) {
        url += `${this.user_id}/`
        methods = 'put'
        message.success = this.$t('update_user')
      }
      this.$http[methods](url, input)
        .then((response) => {
          window.getProspect.getAllUsers()
          window.getPrendTaCom.success(message.success, response)
          this.$emit('closeSidebar')
          this.initValues()
        })
        .catch((error) => {
          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]
            let libelle = ''
            if (item === 'email') {
              libelle = this.$t('mail')
            }  else if (item === 'first_name') {
              libelle = this.$t('Prénoms')
            } else if (item === 'username')  {
              libelle = this.$t('username')
            } else if (item === 'phoneNumber')  {
              libelle = this.$t('Phone')
            } else if (item === 'last_name') {
              libelle = this.$t('Nom')
            }
            for (let j = 0; j < error.response.data[item].length; j++) {
              window.getPrendTaCom.error(`${libelle} :  ${error.response.data[item][j]}`)
            }
          }

          window.getPrendTaCom.error(message.error)
        })
    },
    getAllRegion () {
      this.$http.get('regions/')
        .then((response) => {
          this.regions = response.data
        })
        .catch(() => {
        })
    }
  },
  created () {
    this.user_role = JSON.parse(localStorage.getItem('userInfo')).role
    this.getAllRegion()
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}
</style>
